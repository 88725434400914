<template lang="pug">
base-carousel.image-carousel(
  :ga-event-category='gaEventCategory',
  :ga-event-label='gaEventLabel',
  :ga-event-action-prefix='gaEventActionPrefix',
  :slides='imageList',
  :showArrows='showArrows',
  :showArrowsOutside='showArrowsOutside',
  :showPagination='showPagination',
  :showPaginationOutside='showPaginationOutside',
  :loop='loop',
  slide-class='image-carousel-slide'
)
  template(v-slot:default='{ slide: image }')
    img.image-carousel__img(:src='image.src', :alt='image.alt || ""')
</template>
<script>
import BaseCarousel from './BaseCarousel'
import gaEventMixin from '../../mixins/ga_event'

/*
Each item in imageList should be an object like:
{
  id: 'some-unique-id', // string/number
  src: 'path/to/image', // string
  alt: 'some alt text'  // string
}
*/

export default {
  name: 'ImageCarousel',
  mixins: [gaEventMixin],
  props: {
    imageList: {
      type: Array,
      required: true
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true
    },
    showArrowsOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true
    },
    showPaginationOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    loop: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    BaseCarousel
  }
}
</script>
