<template lang="pug">
.giveaway-points
  iframe.giveaway-points-embed(:src='pointsUrl', allowtransparency='true')
</template>
<script>
export default {
  props: {
    idTokens: {
      type: Array,
      required: true,
      default: () => []
    },
    countColor: {
      type: String,
      required: false,
      default: ''
    },
    copyColor: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    pointsUrl() {
      const baseUrl = `${this.$helpers.giveawayUrl(this.idTokens.join(','))}/points`
      const params = []

      if (this.countColor) {
        params.push(`count-color=${encodeURIComponent(this.countColor)}`)
      }

      if (this.copyColor) {
        params.push(`copy-color=${encodeURIComponent(this.copyColor)}`)
      }

      return params.length ? `${baseUrl}?${params.join('&')}` : baseUrl
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';
.giveaway-points-embed {
  height: $base-spacing-100 * 0.7;
  width: 100%;
  padding-left: $base-spacing-xl;
  padding-right: $base-spacing-xl;
  border: none;
}
</style>
