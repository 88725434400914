<template lang="pug">
page.pbf2042.ff-barlow
  //- Header
  base-header(
    slot='header',
    :showHeaderLinks='false',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel'
  )
    .d-flex.align-items-center.justify-content-center(slot='branding')
      img.bf-polaris-logo(:src='bfPolarisLogoSrc', width='276', height='21', alt='Battlefield 2042 x Polaris')

  //- Content
  template(slot='content')
    //- Video section
    page-section.pbf2042-section.pbf2042-section--video(:fullWidth='true', align='center')
      template(slot='content')
        surf-video(
          :src='videoSrc',
          :auto-play='true',
          :loop='true',
          :cover-mode='true',
          :show-mute-button='true',
          :ga-event-category='gaEventCategory',
          :ga-event-label='gaEventLabel'
        )
        i#jump-icon.icon.icon-md-alt.cursor-pointer(:class='$icons.chevronDown', @click='jumpTo("main")')

    //- Banner whenever needed
    //- page-section.pbf2042-section.pbf2042-section--banner(:fullWidth='true', align='center')
    //-   template(slot='content')
    //-     i#jump-icon.icon.icon-md-alt.cursor-pointer(:class='$icons.chevronDown', @click='jumpTo("main")')

    #main
      //- Main entry section
      page-section.pbf2042-section.pbf2042-section--enter.page-pt-lg(align='center')
        template(slot='content')
          //- Loading
          loading-icon.page-mt-lg-alt.page-mb-xxl(v-if='isLoading', size='lg', color='soft-purple')

          //- Ended view
          .page-mb-lg-alt(v-else-if='isEndedView')
            h1.ff-barlow-condensed Thanks to everyone who entered!
            .fs-md.page-mb-md-alt The weekly and main giveaways have wrapped up.
            image-carousel.pbf2042-carousel(
              :imageList='carouselImages',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel',
              :loop='false',
              ga-event-action-prefix='grandprize_'
            )
            .page-px-md.page-pt-md
              .fs-lg.fw-bold Grand Prize: Battlefield 2042 x Polaris <span class='text-nowrap'>Sportsman ATV!</span>
              .fs-normal.mt-1 Value $29,500
            b-btn.btn-lg.has-icon.has-icon--right.mt-4(
              variant='bf-white',
              :href='endedGrandPrizeUrl',
              target='_blank',
              @mousedown='trackEvent("to_grandprize_giveaway")'
            )
              span.copy View the Giveaway
              i.icon(:class='$icons.externalLink')

          //- Active view
          template(v-else)
            h1.ff-barlow-condensed ENTER DAILY TO WIN A REAL BATTLEFIELD 2042 INSPIRED <span class='text-nowrap'>POLARIS ATV</span>
            .fs-normal.mb-2 Plus chances to score Battlefield 2042 games daily and weekly <span class='text-nowrap'>prize bundles</span>
            .fs-normal.page-mb-md Every daily and weekly prize entry counts towards the <span class='text-nowrap'>Grand Prize!</span>

            //- Active weekly, show "ends in" and embedded view
            .page-pb-lg(v-if='activeGiveaway')
              .page-mb-md.fs-xs(class='sm:fs-md')
                giveaway-ends-in.bg-color-dark-grey.px-3.py-1.rounded-sm(
                  :giveaway='activeGiveaway',
                  label='Weekly Giveaway Ends In'
                  countdown-color='bf-teal'
                )
              giveaway-embed(:id-token='activeGiveaway.idToken', :hide-countdown='true', :hide-footer='true', height='1066')
              .page-mt-lg.fs-normal.fw-bold.position-relative
                .page-mb-xs-alt Earn Bonus Entries for the Grand Prize Below
                i#jump-icon.icon.icon-md-alt.cursor-pointer(:class='$icons.chevronDown', @click='jumpTo("grandprize")')

            //- Not started, or in between weekly giveaways, show "starts in"
            .page-mb-lg.fs-xs(v-else-if='nextGiveaway', class='sm:fs-md')
              giveaway-starts-in.bg-color-dark-grey.px-3.py-1.rounded-sm(
                :giveaway='nextGiveaway',
                :label='isUnderway ? "Next Giveaway Starts In" : "Starts In"',
                countdown-color='bf-teal'
              )

      //- Grand prize bonus entries
      page-section#grandprize.pbf2042-section.pbf2042-section--bonus(v-if='!isEndedView', :fullWidth='true', align='center')
        .page-pt-lg.page-pb-xxl(slot='content')
          //- Grand prize countdown
          .fs-xs.page-mb-md(
            v-if='!isLoading && isUnderway && grandPrizeGiveaway && grandPrizeGiveaway.isActive',
            class='sm:fs-md'
          )
            giveaway-ends-in.bg-color-darkest-grey.px-3.py-1.rounded-sm(
              :giveaway='grandPrizeGiveaway',
              label='Grand Prize Giveaway Ends In'
              countdown-color='bf-teal'
            )

          //- Grand prize details
          image-carousel.pbf2042-carousel(
            :imageList='carouselImages',
            :ga-event-category='gaEventCategory',
            :ga-event-label='gaEventLabel',
            :loop='false',
            ga-event-action-prefix='grandprize_'
          )
          .page-px-md.page-pt-md
            .fs-lg.fw-bold ENTER TO WIN CUSTOM BATTLEFIELD 2042 x POLARIS <span class='text-nowrap'>SPORTSMAN ATV!</span>
            .fs-normal.mt-2 Value $29,500
          template(v-if='isUnderway')
            giveaway-points.page-pt-md(:id-tokens='allIdTokens', count-color='#26FFDF')
            b-btn.btn-lg.mt-4(variant='bf-white', v-b-modal.giveawayModal)
              span.copy Earn Bonus Entries

      //- Weekly giveaway listing
      page-section.pbf2042-section.pbf2042-section--giveaways.page-pt-sm-alt.page-pb-sm(align='center')
        template(slot='content')
          h3.mb-0 6 Weeks of Giveaways
          h6.page-mt-xs-alt
            template(v-if='isEndedView') Revisit any of the ended giveaways
            template(v-else) Chance to win new prizes every week
          loading-icon.page-my-md-alt(v-if='isLoading', size='lg')
          giveaway-list.page-mt-md-alt(
            v-else,
            :ga-event-category='gaEventCategory',
            :ga-event-label='gaEventLabel',
            :list='giveawayList',
            tile-classes='page-mx-xs',
            :show-prizes='false'
          )

      giveaway-modal(
        :id-token='idTokens.grandPrize'
        :ga-event-category='gaEventCategory',
        :ga-event-label='gaEventLabel',
        ga-event-action-prefix='grandprize_'
      )

  //- Footer
  template(slot='footer')
    base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
      template(slot='custom-image')
        img(:src='footerImgSrc', width='135', alt='ESRB Rating')
      .page-mb-md.page-pt-sm.fs-sm.text-center.color-lite-grey(slot='footer-append')
        | Head
        a.px-1.fw-bold.basic-link(
          href='https://atv.polaris.com/en-us/',
          target='_blank',
          @click='trackEvent("to_polaris")'
        ) here
        | for more information on Polaris <span class='text-nowrap'>All-Terrain Vehicles.</span>
</template>
<script>
import BaseHeader from '../components/BaseHeader'
import BaseFooter from '../components/BaseFooter'
import SurfVideo from '../components/SurfVideo'
import ImageCarousel from '../components/carousels/ImageCarousel'
import GiveawayEmbed from '../components/GiveawayEmbed'
import GiveawayPoints from '../components/GiveawayPoints'
import GiveawayStartsIn from '../components/GiveawayStartsIn'
import GiveawayEndsIn from '../components/GiveawayEndsIn'
import GiveawayModal from '../components/modals/GiveawayModal'
import fetchGiveawaysMixin from '../mixins/fetch-giveaways'
import gaEventMixin from '../mixins/ga_event'

export default {
  name: 'PolarisBF2042',
  mixins: [fetchGiveawaysMixin, gaEventMixin],
  data() {
    return {
      isLoading: true,
      isEndedView: false,
      isUnderway: false,
      bfPolarisLogoSrc: require('../assets/themes/polaris-bf2042/images/battlefield-2042-polaris-logo.png'),
      videoSrc: require('../assets/themes/polaris-bf2042/video/POL_BF_60_4K_11242021_FINAL_Compressed.mp4'),
      footerImgSrc: require('../assets/themes/polaris-bf2042/images/esrb-rating-icon.svg'),
      carouselImages: [
        {
          id: 'image-1',
          src: require('../assets/themes/polaris-bf2042/images/carousel-image-1.jpg'),
          alt: 'Grand Prize'
        },
        {
          id: 'image-2',
          src: require('../assets/themes/polaris-bf2042/images/carousel-image-2.jpg'),
          alt: 'Polaris Sportsman ATV'
        },
        {
          id: 'image-3',
          src: require('../assets/themes/polaris-bf2042/images/carousel-image-3.jpg'),
          alt: 'Polaris Sportsman ATV'
        }
      ],
      idTokens: {
        grandPrize: '6ONRtnU',
        weekly: ['6HDvPcZ', 'fU4OFw8', 'B2D-OfO', 'XbBl5L4', 'fWyJ8Lu', '68Ktfn2']
      },
      grandPrizeGiveaway: null,
      giveawayList: [],
      activeGiveawayInterval: null,
      activeGiveaway: null,
      nextGiveaway: null
    }
  },
  computed: {
    allIdTokens() {
      return [this.idTokens.grandPrize, ...this.idTokens.weekly]
    },
    endedGrandPrizeUrl() {
      return `${this.$helpers.giveawayUrl(this.idTokens.grandPrize)}/ended`
    }
  },
  watch: {
    isEndedView() {
      if (this.isEndedView) {
        clearInterval(this.activeGiveawayInterval)
      }
    }
  },
  async created() {
    const grandPrizeGiveaway = await this.fetchGiveawaysByIdTokens([this.idTokens.grandPrize])
    this.grandPrizeGiveaway = grandPrizeGiveaway.length ? grandPrizeGiveaway[0] : null

    const giveawayList = await this.fetchGiveawaysByIdTokens(this.idTokens.weekly)
    this.giveawayList = giveawayList.sort((a, b) => (a.startTime > b.startTime ? 1 : -1))

    this.activeGiveawayCheck()
    this.activeGiveawayInterval = setInterval(this.activeGiveawayCheck, 1000)
    this.isLoading = false
  },
  beforeDestroy() {
    clearInterval(this.activeGiveawayInterval)
  },
  methods: {
    jumpTo(id) {
      this.trackEvent(`jump_to_${id}`)
      this.$helpers.scrollToId(id)
    },
    getGiveawayByIdToken(idToken) {
      return this.giveawayList.find((g) => g.idToken === idToken)
    },
    activeGiveawayCheck() {
      // Are the giveaways underway?
      const activeGiveaway = this.giveawayList.find((g) => g.isActive)
      const endedGiveaways = this.giveawayList.filter((g) => g.isEnded)
      this.isUnderway = !!activeGiveaway || !!endedGiveaways.length

      // Do we have an active giveaway?
      if (activeGiveaway) {
        this.activeGiveaway = activeGiveaway
        return
      }
      this.activeGiveaway = null

      // Do we have upcoming giveaways, if not set ended view
      const upcomingGiveaways = this.giveawayList.filter((g) => g.isUpcoming)
      if (!upcomingGiveaways.length) {
        this.isEndedView = true
        return
      }

      // Otherwise, determine the next giveaway from the upcoming list
      let nextGiveaway
      upcomingGiveaways.forEach((g) => {
        if (!nextGiveaway || g.startsIn < nextGiveaway.startsIn) {
          nextGiveaway = g
        }
      })
      this.nextGiveaway = nextGiveaway
    }
  },
  metaInfo() {
    return {
      title: 'BATTLEFIELD 2042 x POLARIS',
      titleTemplate: '%s | Surf Giveaways'
    }
  },
  components: {
    BaseHeader,
    BaseFooter,
    SurfVideo,
    ImageCarousel,
    GiveawayEmbed,
    GiveawayPoints,
    GiveawayStartsIn,
    GiveawayEndsIn,
    GiveawayModal
  }
}
</script>
<style lang="scss" src="../assets/themes/polaris-bf2042/scss/main.scss" />
