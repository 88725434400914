import { render, staticRenderFns } from "./PolarisBF2042.vue?vue&type=template&id=10a3b6f2&lang=pug&"
import script from "./PolarisBF2042.vue?vue&type=script&lang=js&"
export * from "./PolarisBF2042.vue?vue&type=script&lang=js&"
import style0 from "../assets/themes/polaris-bf2042/scss/main.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports