<template lang="pug">
.carousel-wrap(:class='carouselWrapClasses')
  swiper.carousel(ref='surfSwiper', :options='swiperOptions')
    swiper-slide.carousel-slide(
      v-for='slide, index in slides',
      :key='`slide-${slide.id}`',
      :class='`carousel-slide--${index} ${slideClass}`'
    )
      slot(:slide='slide')
    template(v-if='showArrows && !showArrowsOutside')
      .swiper-button-prev.carousel-button-prev.carousel-button(slot='button-prev')
      .swiper-button-next.carousel-button-next.carousel-button(slot='button-next')
    template(v-if='showPagination && !showPaginationOutside')
      .swiper-pagination.carousel-pagination(slot='pagination')
  .carousel__pagination-outside(v-if='showPagination && showPaginationOutside')
    .swiper-pagination.carousel-pagination(slot='pagination')
  .carousel__arrows-outside(v-if='showArrows && showArrowsOutside')
    .carousel__arrows-outside-prev.carousel-button-prev
      i.icon.icon-md(:class='$icons.chevronLeft')
    .carousel__arrows-outside-next.carousel-button-next
      i.icon.icon-md(:class='$icons.chevronRight')
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import gaEventMixin from '../../mixins/ga_event'

export default {
  name: 'BaseCarousel',
  mixins: [gaEventMixin],
  props: {
    slides: {
      type: Array, // of objects, each object must contain an "id" property
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true
    },
    showArrowsOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true
    },
    showPaginationOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    slideClass: {
      type: String,
      required: false,
      default: ''
    },
    slideActiveClass: {
      type: String,
      required: false,
      default: 'active'
    },
    slidesPerView: {
      type: Number,
      required: false,
      default: 1
    },
    spaceBetween: {
      type: Number,
      required: false,
      default: 2 // px
    },
    initialSlideIndex: {
      type: Number,
      required: false,
      default: 0
    },
    loop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    swiper() {
      return this.$refs.surfSwiper.$swiper
    },
    carouselWrapClasses() {
      const classes = []

      if (this.showPagination && this.showPaginationOutside) {
        classes.push('carousel-wrap--pagination-outside')
      }

      if (this.showArrows && this.showArrowsOutside) {
        classes.push('carousel-wrap--arrows-outside')
      }

      if (this.showPagination && this.showPaginationOutside && this.showArrows && this.showArrowsOutside) {
        classes.push('carousel-wrap--navs-outside')
      }

      return classes.join(' ')
    },
    swiperOptions() {
      const options = {
        slidesPerView: this.slidesPerView,
        spaceBetween: this.spaceBetween,
        initialSlide: this.initialSlideIndex,
        slideActiveClass: this.slideActiveClass,
        loop: this.loop,
        centeredSlides: true,
        simulateTouch: true,
        grabCursor: true,
        ...this.options
      }

      if (this.showArrows) {
        options['navigation'] = {
          nextEl: '.carousel-button-next',
          prevEl: '.carousel-button-prev'
        }
      }

      if (this.showPagination) {
        options['pagination'] = {
          el: '.carousel-pagination',
          clickable: true
        }
      }

      return options
    }
  },
  mounted() {
    this.swiper.on('slideChange', () => {
      this.trackEvent(`${this.gaEventActionPrefix}carousel_change_to_${this.swiper.activeIndex}`)
    })
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  }
}
</script>
