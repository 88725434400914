<template lang="pug">
b-modal#giveawayModal(
  ref='giveawayModal',
  modal-class='modal--lg modal--tall modal--no-footer',
  body-class='modal-body--iframe',
  footer-class='modal-footer--buttons',
  centered='',
  hide-footer='',
  @show='onShow',
  @hide='onHide'
)
  //- Header Close
  template(slot='modal-header-close')
    i.icon(:class='$icons.close')

  //- Modal Body
  iframe(v-if='iframeEnabled', :src='giveawayUrl')
</template>
<script>
import gaEventMixin from '../../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  data() {
    return {
      iframeEnabled: false
    }
  },
  props: {
    idToken: {
      type: String,
      required: true
    },
    gaEventActionPrefix: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    giveawayUrl() {
      return `${this.$surf.links.giveawayBase}/${this.idToken}/embed`
    }
  },
  methods: {
    onShow() {
      this.trackEvent(`${this.gaEventActionPrefix}giveaway_modal_open`)
      this.iframeEnabled = true
    },
    onHide() {
      this.trackEvent(`${this.gaEventActionPrefix}giveaway_modal_close`)
      this.iframeEnabled = false
    },
    hide() {
      this.$refs.previewContestModal.hide()
    },
    show() {
      this.$refs.previewContestModal.show()
    }
  }
}
</script>
